@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;600;700&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Noto+Serif+Bengali:wght@400;500;700;800&display=swap");
.main {
  height: 100vh;
  font-family: "IBM Plex Sans", sans-serif;
}

.main-content {
  width: 1600px;
}
.h-84 {
  height: 84px;
}
/* Header Section Start*/
.header-bg {
  background-color: #140f2e;
  position: relative;
  z-index: 9999;
}
.logo {
  width: 250px;
  height: 100%;
}
.logo img {
  width: 50%;
}
.menu > ul > li {
  display: inline-flex;
  margin-right: 40px;
  color: #8f899e;
  align-items: center;
  font-family: "IBM Plex Sans", sans-serif;
}

.menu > ul > li > img {
  margin-left: 8px;
}
.menu > ul > .active {
  color: #f1ebff;
}
.header-register-btn {
  padding: 10px 48px;
  border: 1px solid #47357b;
  border-radius: 10px;
  background-color: #1b1339;
}

.login-register-btn {
  padding: 10px 48px;
  border: 1px solid #47357b;
  border-radius: 10px;
  background-color: #5b3cae;
}
/* Header Section End*/
/* Hero Section Start*/
.hero-bg {
  min-height: 954px;
  background: url("../images/HeroTop.svg") center top no-repeat,
    url("../images/HeroBg.svg") top right no-repeat,
    url("../images/HeroNoise.svg") center no-repeat,
    url("../images/HeroBottom.svg") center bottom no-repeat;
  background-color: #0f0321;
  background-size: contain;
  overflow: hidden;
  border-bottom: 1px solid #f5f5f5;
}
.hero-content {
  width: 626px;
  height: 100%;
  gap: 20px;
}
.hero-content-input {
  width: 400px;
  background-color: #221447;
  padding: 10px;
  border: 1px solid #31254f;
  border-radius: 20px;
}
.hero-content-input-group {
  background-color: #291f57;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.hero-content-input-group:last-child {
  margin-bottom: 0;
}
.hero-content-input-group input {
  height: 56px;
  background: unset;
  border: 1px solid #3f3861;
  border-radius: 10px;
  margin-bottom: 15px;
}
.hero-content-btn {
  width: 100%;
  height: 56px;
  background-color: #5e40af;
  border-radius: 15px;
  margin-top: 10px;
  border: 1px solid #4c3a88;
}
/* Hero Section End*/
/* Second Section Start*/
.second-section {
  background-color: #1c1235;
  border-bottom: 1px solid #f5f5f5;
  padding: 70px 0;
}
.explore-btn {
  width: 200px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #3e2878;
  border: 1px solid #13102e;
  border-radius: 12px;
}
.robot-bg {
  background-image: url("../images/RobotBg2.svg");
  background-repeat: no-repeat;
}
.matrics-section > ul {
  width: 70%;
}
.matrics-section > ul > li {
  color: #d7d1e3;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #392b56;
  border-radius: 50px;
  border: 1px solid #6b71a8;
  flex-direction: column;
  margin-bottom: 20px;
}
.matrics-section > ul > li > h2 {
  font-size: 24px;
}
.matrics-section > ul > li > h6 {
  font-size: 16px;
}

/* Second Section End*/

/* Third Section Start*/
.third-section {
  height: 496px;
  background: url("../../assets/images/third-bg.svg");
  background-repeat: no-repeat;
  background-color: #0b0116;
  background-position: center;
}
.third-section-bg {
  background: url("../images/Cylinder5-Transparent2.svg") top right no-repeat,
    url("../images/Cylinder5-Transparent.svg") bottom left no-repeat;
  background-repeat: no-repeat;
}
.invest-btn {
  padding: 9px 28px;
  border: 1px solid #b59be9;
  background-color: #442c85;
  color: #f1ebff;
  border-radius: 12px;
  margin-right: 16px;
}

.talk-btn {
  padding: 9px 28px;
  border: 1px solid #b59be9;
  color: #8f899e;
  border-radius: 12px;
}
/* Third Section End*/
/* Four Section Start*/
.forth-section {
  background-color: #0f041d;
  padding: 120px 0;
}
.forth-section-content {
  background-image: url("../images/four-bg-1.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  width: 100%;
}
.forth-section-content ul > li {
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 44px;
  position: relative;
}
.forth-section-content ul > li ::before {
  content: ""; /* Empty content */
  position: absolute; /* Set position to absolute */
  top: 50px;
  left: 30px;
  bottom: 20px;
  height: 100%; /* Set height to cover the entire height of the list item */
  width: 1px; /* Set the width of the vertical line */
  background-color: #271f35; /* Set the color of the vertical line */
}

.forth-section-content ul > .active ::before {
  top: 70px;
  left: 35px;
}
.forth-section-content ul > li > div {
}
.forth-section-content ul > li:last-child ::before {
  content: none;
}
.forth-section-content ul > li > h4 {
  font-size: 24px;
  color: #524961;
}

.forth-section-content ul > .active > h4 {
  font-size: 28px;
  color: white;
}

.forth-section-content ul > li > div {
  font-size: 18px;
  color: #413f5a;
  margin-left: 90px;
  border-left: 1px solid #7256ae;
  padding-left: 10px;
}
.forth-section-content img {
  object-fit: contain;
}
/* Four Section End*/
/* Footer Section Start*/
.footer-bg {
  background-color: #130e2b;
}
.footer-content {
  padding: 71px 0 32px 0;
}
.footer-social > li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.footer-menu > ul {
  padding-left: 150px;
}
.footer-menu > ul > li {
  padding: 12px;
  color: #77718a;
}
.footer-bottom {
  border-top: 1px solid #77718a;
  padding: 16px 0 32px 0;
  text-align: center;
}
/* Footer Section End*/
